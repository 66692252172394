import React from 'react';
import { DNA } from 'react-loader-spinner';

const LoadingSpinner = ({ visible }) => {
    return (
        <div className="container text-center mt-5">
            <DNA
                visible={visible}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperclassName="dna-wrapper"
            />
        </div>
    );
};

export default LoadingSpinner;
