import React, { useState } from 'react';
import { updateProfileImage } from '../../services/ProfileService';


const ImageUploadModal = ({ isOpen, onClose, onImageUpdate, imageField }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  if (!isOpen) return null;

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append(imageField, selectedFile);

    try {
        
      const response = await updateProfileImage(formData);
      if (response.status === 200) {
        onImageUpdate(response.data[imageField]);
        onClose();
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Update {imageField === 'photo1' ? 'Profile Photo 1' : 'Profile Photo 2'}
            </h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body text-center">
            <input type="file" className="form-control mb-3" onChange={handleFileChange} />
          </div>
          <div className="modal-footer">
            <button onClick={handleImageUpload} className="btn btn-primary">Upload</button>
            <button onClick={onClose} className="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
