import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'; // Adjust the import path as needed
import initialDataReducer from '../features/tliservice/initialService'; // Adjust the import path as needed
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reference to make it persistance 
// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/
const persistConfig = {
  key: "root",
  storage,
};



const persistedInDataReducer = persistReducer(persistConfig, initialDataReducer);

const store = configureStore({
  reducer: {
    auth: authReducer,
    indata: persistedInDataReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persistor = persistStore(store);
export default store;