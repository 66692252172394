import React,{useEffect, useState} from "react";
import styles from "../../css/home.module.css";
import TypeWriterEffect from "react-typewriter-effect";
import { useDispatch, useSelector } from 'react-redux';
import { RiAdminFill } from "react-icons/ri";
import { setStateData, UserProfileExistOrNot, allChoicesFromBackend } from "../../features/tliservice/initialService";
import { Link } from 'react-router-dom';
import { HiOutlineLogout } from "react-icons/hi";
import {notifySuccess } from '../../services/NotificationService'; // Import notification service
import { logout } from '../../features/auth/authSlice'; // Adjust the import path as needed
import { clearTokens } from '../../utils/commonutils';
import { getStatesData, getProfileUrl, getProfileChoiceFieldsUrl } from "../../services/ProfileService";

function Home() {
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.is_logged_in);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stateData = await getStatesData();
        const profileData = await getProfileUrl();
        const allChoices = await getProfileChoiceFieldsUrl();
        console.log(allChoices.data)
        dispatch(setStateData(stateData.data));
        dispatch(UserProfileExistOrNot(profileData.data));
        dispatch(allChoicesFromBackend(allChoices.data));

      } catch (err) {
        console.error('Loading all initial data', err);
      }
    };
    fetchData();
  }, [dispatch]);


  const handleLogout = () => {
    dispatch(logout());
    clearTokens()
    notifySuccess("logged out!")
    };


  return (
    <>
      <div className="home">
        <div className={`${styles.hero}`}>
          <div className="container-fluid">
            <div className={`row d-flex flex-row ${styles.main}`}>
              <div className="col-md-6 d-flex justify-content-center  flex-row">
                <div className="col-md-6 mt-5 align-self-center mt-5 ">
                <h1 className={styles.greeting}>Hi EveryOne,</h1>
                  <div className="ms-3">
                    <h2>WELCOME TO TLINIKAH</h2>
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "Red Hat Display",
                        color: "#FF0000",
                        fontWeight: 80,
                        fontSize: "1.1em",
                      }}
                      startDelay={2000}
                      cursorColor="#3F3D56"
                      multiText={[
                        "CONNECTING MUSLIM HEARTS WITH FAITH",
                        "BUILDING HALAL RELATIONSHIPS FOR THIS LIFE AND THE HEREAFTER",
                        "FOLLOWING SUNNAH TO FIND YOUR OTHER HALF",
                        "A JOURNEY TOWARDS A BLESSED MARRIAGE"
                      ]}
                      multiTextDelay={2000}
                      typeSpeed={90}
                      multiTextLoop
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-center">
                <div className="align-self-center">

                    {/* Elegant Heading */}
                    <h2 className="text-center mb-4" style={{ fontWeight: "600", color: "#2c3e50" }}>
                        Begin Your Blessed Journey
                    </h2>
                    <p className="text-center mb-5" style={{ fontStyle: "italic", color: "#7f8c8d" }}>
                        Join today and connect with your other half in a halal way, following the Sunnah.
                    </p>
                    <div className="text-center">
                        {isLoggedIn ? (
                            <>
                                <Link
                                    className="btn btn-outline-secondary me-3"
                                    to="/"
                                    onClick={handleLogout}
                                >
                                    <HiOutlineLogout style={{ marginRight: '3px' }} />
                                    Logout
                                </Link>
                                <Link
                                    className="btn btn-outline-secondary"
                                    to="/profile"
                                >
                                    <RiAdminFill style={{ marginRight: '3px' }} />
                                    Profile
                                </Link>

                              
                                <div className="mt-3">
                                  <Link
                                      className="btn btn-outline-secondary"
                                      to="/searchprofile"
                                  >
                                      <RiAdminFill style={{ marginRight: '3px' }} />
                                      Search Partner
                                  </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <Link
                                    className="btn btn-outline-secondary me-3"
                                    to="/login"
                                >
                                    Login
                                </Link>
                                <Link
                                    className="btn btn-outline-secondary"
                                    to="/register"
                                >
                                    Register
                                </Link>
                            </>
                        )}
                    </div>


                </div>
                </div>

            </div>

            
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
