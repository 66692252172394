import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_logged_in: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState, 
  reducers: {
    login: (state) => {
      state.is_logged_in = true;
    },
    logout: (state) => {
      state.is_logged_in = false;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
