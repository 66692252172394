import React, { useState, useEffect } from 'react';
import { getAllCityByStateID , updateProfile} from '../../services/ProfileService';
import { notifySuccess } from '../../services/NotificationService';
import { useSelector } from 'react-redux';
import ImageUploadModal from './ImageUploadModal';

const UserProfile = ({ profileData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ ...profileData });
  const [cities, setCities] = useState([]);
  const states = useSelector((state) => state.indata.state_data);
  const choices = useSelector((state) => state.indata.all_choice_field_from_backend);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageField, setImageField] = useState('');

  const handleOpenModal = (field) => {
    setImageField(field);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageUpdate = (newImageUrl) => {
    // Handle updating the image URL in formData (this could be a state update in the parent component)
    console.log("from user profile ", newImageUrl)
    formData[imageField] = newImageUrl;
  };

  useEffect(() => {
    const getAllCityWithStateID = async () => {
      try {
        const response = await getAllCityByStateID(formData.state); 
        setCities(response.data); 
      } catch (error) {
        console.error('Error fetching choice fields:', error);
      }
    };

    getAllCityWithStateID();
  }, []);



  // Fetch cities when the selected state changes
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;

    // Update formData state
    setFormData((prevData) => ({
        ...prevData,
        state: selectedState, // Update the state value in formData
        city: "", // Reset city when state changes
    }));

    if (selectedState) {
      try {
        const response = await getAllCityByStateID(selectedState); // Adjust the URL based on your API
        setCities(response.data); // Assuming your API returns an array of cities for the selected state
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    } else {
      setCities([]); // Clear cities if no state is selected
    }
    };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        console.log(formData)
        await updateProfile(formData);
        notifySuccess("updated successfully !!")
        // Optionally, you can reset the form or update local state here
      } catch (error) {
        console.error('Error updating profile:', error);
      }
  
  };

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h2 className="mb-3">Your Profile</h2>
        <p className="text-muted">Review and update your profile information</p>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Personal Information Section */}
        <div className="card mb-4">
      <div className="card-header">
        <h5 className="mb-0">Personal Information</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Full Name</label>
            <input
              type="text"
              className="form-control"
              name="full_name"
              value={`${formData.full_name}`}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Gender</label>
            <select
              className="form-select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.gender && Object.entries(choices.gender).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>


        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Height (cm)</label>
            <input
              type="number"
              className="form-control"
              name="height"
              value={formData.height}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Weight (kg)</label>
            <input
              type="number"
              className="form-control"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">About Me</label>
            <textarea
              className="form-control"
              name="about_me"
              value={formData.about_me}
              onChange={handleChange}
              readOnly={!isEditing}
              rows="3"
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Monthly Salary Range</label>
            <input
              type="text"
              className="form-control"
              name="monthly_salary_range"
              value={formData.monthly_salary_range}
              onChange={handleChange}
              readOnly={!isEditing}
            />
          </div>
        </div>


        <div className="row">
            <div className="col-md-6 mb-3">
                <label className="form-label">State</label>
                <select
                  className="form-select"
                  name="state"
                  value={formData.state || ''}
                  onChange={handleStateChange}
                  disabled={!isEditing}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">City</label>
                <select
                  className="form-select"
                  name="city"
                  value={formData.city || ''}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  disabled={!isEditing}
                >
                  <option value="">Select City</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
            </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Native State Language</label>
            <select
              className="form-select"
              name="native_state_language"
              value={formData.native_state_language}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.native_state_language && Object.entries(choices.native_state_language).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Resident State Language</label>
            <select
              className="form-select"
              name="resident_state_language"
              value={formData.resident_state_language}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.resident_state_language && Object.entries(choices.resident_state_language).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Education</label>
            <select
              className="form-select"
              name="education"
              value={formData.education}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.education && Object.entries(choices.education).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Profession</label>
            <select
              className="form-select"
              name="profession"
              value={formData.profession}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.profession && Object.entries(choices.profession).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">

          <div className="col-md-6 mb-3">
          <label className="form-label">Deeni Interest</label>
          <select
              className="form-select"
              name="deeni_interest"
              value={formData.deeni_interest}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.deeni_interest && Object.entries(choices.deeni_interest).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>


          <div className="col-md-6 mb-3">
            <label className="form-label">Revert</label>
            <select
              className="form-select"
              name="revert"
              value={formData.revert}
              onChange={handleChange}
              disabled={!isEditing}
            >
              {choices.revert && Object.entries(choices.revert).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>


        <div className="row">

            <div className="col-md-6 mb-3">
                <label className="form-label">Marital Status</label>
                <select
                    className="form-select"
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleChange}
                    disabled={!isEditing}
                >
                    {choices.marital_status && Object.entries(choices.marital_status).map(([key, value]) => (
                    <option key={key} value={key}>
                        {value}
                    </option>
                    ))}
                </select>
            </div>
            </div>
      </div>
    </div>

        {/* Family Information Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Family Information</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Father's Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="father_name"
                  value={formData.father_name}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Mother's Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="mother_name"
                  value={formData.mother_name}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Father's Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  name="father_occupation"
                  value={formData.father_occupation}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Mother's Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  name="mother_occupation"
                  value={formData.mother_occupation}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
            </div>


            <div className="row">
                <div className="col-md-6 mb-3">
                    <label className="form-label">About Family</label>
                    <textarea
                    className="form-control"
                    name="about_family"
                    value={formData.about_family}
                    onChange={handleChange}
                    readOnly={!isEditing}
                    rows="3"
                    />
                </div>
            </div>
          </div>
        </div>


        {/* Partner Preferences Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Partner Preferences</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Partner Revert</label>
                <select
                  className="form-select"
                  name="partner_revert"
                  value={formData.partner_revert}
                  onChange={handleChange}
                  disabled={!isEditing}
                >
                  {choices.partner_revert && Object.entries(choices.partner_revert).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Partner Requirements</label>
                <textarea
                  className="form-control"
                  name="partner_requirement"
                  value={formData.partner_requirement}
                  rows="3"
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
            </div>
            </div>

          </div>
        </div>


        {/* Image Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Image Section</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Profile Photo 1</label>
                {formData.photo1 && (
                  <div className="mb-2">
                    <img
                      src={formData.photo1}
                      alt="Profile Photo 1"
                      className="img-thumbnail"
                      onClick={() => handleOpenModal('photo1')}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Profile Photo 2</label>
                {formData.photo2 && (
                  <div className="mb-2">
                    <img
                      src={formData.photo2}
                      alt="Profile Photo 2"
                      className="img-thumbnail"
                      onClick={() => handleOpenModal('photo2')}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

      {/* Modal for Image Update */}
        <ImageUploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onImageUpdate={handleImageUpdate}
          imageField={imageField}
        />
      </div>







        {/* Edit and Submit Buttons */}
        <div className="text-center">
          {isEditing ? (
            <>
              <button type="submit" className="btn btn-primary me-2">
                Save Changes
              </button>
              <button type="button" className="btn btn-secondary" onClick={() => setIsEditing(false)}>
                Cancel
              </button>
            </>
          ) : (
            <button type="button" className="btn btn-warning" onClick={() => setIsEditing(true)}>
              Edit Profile
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
