import React, {useEffect }  from 'react';
import { Link } from 'react-router-dom';
import styles from "../css/navbar.module.css";
import { AiOutlineHome, AiOutlineLogin } from "react-icons/ai";
import { MdPrivacyTip } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
import { ImBlog } from "react-icons/im";
import { IoBriefcase } from "react-icons/io5";
import { HiOutlineLogout } from "react-icons/hi";
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../features/auth/authSlice'; // Adjust the import path as needed
import { clearTokens, getAccessToken } from '../utils/commonutils';
import {notifySuccess } from '../services/NotificationService'; // Import notification service

function Navbar() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.is_logged_in);
    // this will run when after then component rendering 
    useEffect(() => {
        const token = getAccessToken();
        if (token) {
            dispatch(login(token));
        } else {
            dispatch(logout());
        }
    });

    const handleLogout = () => {
        dispatch(logout());
        clearTokens()
        notifySuccess("logged out!")
    };

    return (
        <>
            <nav className="navbar sticky-top navbar-expand-lg" style={{ backgroundColor: "#28a745", zIndex: "2" }} data-bs-theme="dark">

            <div className="container-fluid">
                <Link className={`navbar-brand p-2 ${styles.head} `} to="/">TLINIKAH</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon border border-danger"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navbarHover">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">    <span style={{ fontSize: "20px" }} ><AiOutlineHome /></span>  Home</Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/">    <span style={{ fontSize: "20px" }} ><AiOutlineHome /></span>  Contact Us</Link>
                        </li>

                        {isLoggedIn ? (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" onClick={handleLogout}> <span style={{ fontSize: "20px", marginRight: "3px" }} ><HiOutlineLogout /></span>Logout</Link>
                                </li>
                                
                                <li className="nav-item">
                                <Link className="nav-link" to="/profile"> <span style={{ fontSize: "20px", marginRight: "3px" }} ><RiAdminFill /></span>Profile</Link>
                                </li>
                            
                            </>
   
                        ) : (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/register"> <span style={{ fontSize: "20px", marginRight: "3px" }} ><SiGnuprivacyguard /></span>Register</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login"> <span style={{ fontSize: "20px", marginRight: "3px" }} ><AiOutlineLogin /></span>Login</Link>
                                </li>
                            </>
                        )}

                        <li className="nav-item">
                            <Link className="nav-link " aria-current="page" to="https://merchant.razorpay.com/policy/PBhqMGnSRo6VXr/terms">    <span style={{ fontSize: "20px" }} ><MdPrivacyTip /></span> Terms and Conditions </Link>
                        </li>
                    </ul>
                </div>
            </div>
            </nav>
        </>
    );
}

export default Navbar;
