// ApiService.js
import { setAccessToken, setRefreshToken } from '../utils/commonutils';
import { axiosProtectedInstance, axiosNormalInstance } from './BaseService';

export const LoginUsersUrl = async (LoginData) => {
    try {
        const response = await axiosNormalInstance.post('/api/user/login/', LoginData);
        setAccessToken(response.data.token.access);
        setRefreshToken(response.data.token.refresh);

    } catch (error) {
        throw error;
    }
};

export const getUserAbout = async () => {
    try {
        const response = await axiosProtectedInstance.get('/api/user/profile/');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const registerUserUrl = async (SignUpData) => {
    try {
        const response = await axiosNormalInstance.post('/api/user/register/', SignUpData);
        setAccessToken(response.data.token.access);
        setRefreshToken(response.data.token.refresh);
        return response.data;
    } catch (error) {
        // If error response is available, throw specific error messages
        if (error.response) {
            throw new Error(error.response.data.detail || 'Registration failed. Please try again.');
        }
        // Handle network errors or other errors
        throw new Error('Network error. Please check your connection and try again.');
    }
};
