// ApiService.js
import {axiosProtectedInstance, axiosNormalInstance } from './BaseService';


export const getPaymentStatus = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/payment/status/');
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  
  

  export const getProfileUrl = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/profile/exists/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  