import Home from "./Components/Home/Home";
import Navbar from './Common/Navbar';
import Footer from "./Common/Footer";
import Login from "./Components/Auth/Login";
import SignUp from "./Components/Auth/Signup";
import {ToastContainer } from 'react-toastify';
import Profile from "./Components/Home/Profile";
import SearchProfile from "./Components/Home/SearchProfile";
import { Route, Routes } from 'react-router-dom';
import SingleUserProfile from "./Components/Home/SingleUserProfile";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<SignUp/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/searchprofile" element={<SearchProfile/>}/>
        <Route path="/profile/:id" element={<SingleUserProfile/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
