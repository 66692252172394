import React, { useState } from "react";
import { createProfileUrl , getAllCityByStateID} from "../../services/ProfileService";
import { notifySuccess, notifyError } from '../../services/NotificationService';
import UserProfile from "./UserProfile";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

function Profile() {

  const [step, setStep] = useState(1);
  const [cities, setCities] = useState([]);
  const states = useSelector((state) => state.indata.state_data);
  const pdata = useSelector((state) => state.indata.user_profile_exist_or_not);
  const choices = useSelector((state) => state.indata.all_choice_field_from_backend);
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    date_of_birth: "",
    gender: "",
    revert: "",
    deeni_interest: "",
    phone_number: "",
    native_state_language: "",
    resident_state_language: "",
    state: "",
    city: "",
    address: "",
    marital_status: "",
    height: "",
    weight: "",
    about_me: "",
    about_family: "",
    father_name: "",
    father_occupation: "",
    mother_name: "",
    mother_occupation: "",
    education: "",
    profession: "",
    monthly_salary_range: "",
    partner_revert: "",
    partner_requirement: "",
    photo1: null,
    photo2: null,   
  });

   // Fetch cities when the selected state changes
    const handleStateChange = async (e) => {
    const selectedState = e.target.value;

    // Update formData state
    setFormData((prevData) => ({
        ...prevData,
        state: selectedState, // Update the state value in formData
        city: "", // Reset city when state changes
    }));

    if (selectedState) {
      try {
        const response = await getAllCityByStateID(selectedState); // Adjust the URL based on your API
        setCities(response.data); // Assuming your API returns an array of cities for the selected state
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    } else {
      setCities([]); // Clear cities if no state is selected
    }
    };


    const handleChange = (e) => {
        const { name, value, files } = e.target;
      
        // If the input is a file input, use `files[0]`
        if (files) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0], // Save the file object
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value, // Save other form fields
          }));
        }
      };
    

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
        // Validation: Check if all fields except images are filled
        const requiredFields = [
          "date_of_birth",
          "gender",
          "revert",
          "deeni_interest",
          "phone_number",
          "native_state_language",
          "resident_state_language",
          "state",
          "city",
          "marital_status",
          "height",
          "weight",
          "education",
          "profession",
          "partner_revert",
          "partner_requirement",
          "father_name",
          "father_occupation",
          "mother_name",
          "mother_occupation",
          "address",
          "about_me",
          "about_family",
          "photo1",
          "photo2"
      ];
  
      // Collect missing fields
      const missingFields = requiredFields.filter(field => !formData[field]);
  
      if (missingFields.length > 0) {
          // Create a message listing the missing fields
          const missingFieldsMessage = missingFields.join(", ");
          notifyError(`Empty field: ${missingFieldsMessage}`);
          return; // Prevent submission
      }
    const formDataToSubmit = new FormData();
  
    // Loop through the form data and append it to formDataToSubmit
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
  
    try {
      // Use the centralized createProfileUrl function to submit the form data
      const response = await createProfileUrl(formDataToSubmit);
      notifySuccess("Profile created !");
      navigate("/")
      window.location.reload(); // This will reload the current page

      // Handle successful response (e.g., show a success message or redirect)
    } catch (error) {
      console.error("Error submitting form:", error.response ? error.response.data : error.message);
      // Handle error (e.g., show an error message)
    }
  };
  



  return (
    <div className="container mt-5">
        {pdata.profile_exists ? (
          <>
              <UserProfile profileData={pdata.profile_data}  is_editable={true}  />
          </>
        ) : (
          <>
          <div className="text-center mb-4">
            <h4>Step {step} of 4</h4>
          </div>
          <h1 className="text-center mb-4">Create Profile</h1>
          <form className="bg-light p-4 rounded shadow" onSubmit={handleSubmit}  encType="multipart/form-data">

            {step === 1 && (
              <>

            <div className="form-group mb-3">
                    <label>Native State Language</label>
                    <select
                    className="form-control"
                    name="native_state_language"
                    value={formData.native_state_language}
                    onChange={handleChange}
                    required
                    >
                    <option value="">Select Native Language</option>
                    {choices.native_state_language && Object.entries(choices.native_state_language).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                    </select>
                </div>

                <div className="form-group mb-3">
                    <label>Resident State Language</label>
                    <select
                    className="form-control"
                    name="resident_state_language"
                    value={formData.resident_state_language}
                    onChange={handleChange}
                    required
                    >
                    <option value="">Select Resident Language</option>
                    {choices.resident_state_language && Object.entries(choices.resident_state_language).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                    </select>
                </div>


                <div className="form-group mb-3">
                  <label htmlFor="phone_number">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="date_of_birth">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    value={formData.date_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>


                <div className="form-group mb-3">
                  <label>Gender</label>
                  <select
                    className="form-control"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    {choices.gender && Object.entries(choices.gender).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="form-group mb-3">
                <label>Deeni Interest</label>
                <select
                    className="form-control"
                    name="deeni_interest"
                    value={formData.deeni_interest}
                    onChange={handleChange}
                    required
                >
                {choices.deeni_interest && Object.entries(choices.deeni_interest).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
                </select>
                </div>

                <div className="form-group mb-3">
                <label>Profession</label>
                <select
                    className="form-control"
                    name="profession"
                    value={formData.profession}
                    onChange={handleChange}
                    required
                >
                  {choices.profession && Object.entries(choices.profession).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                </div>




                <div className="form-group mb-3">
                  <label>Revert</label>
                  <select
                    className="form-control"
                    name="revert"
                    value={formData.revert}
                    onChange={handleChange}
                    required
                  >
                  {choices.revert && Object.entries(choices.revert).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                  </select>
                </div>
                <button type="button" className="btn btn-primary" onClick={handleNext}>
                  Next
                </button>
              </>
            )}

            {step === 2 && (
              <>

                <div className="form-group mb-3">
                  <label>Marital Status</label>
                  <select
                    className="form-control"
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleChange}
                    required
                    id="marital_status"
                  >
                    <option value="">Select Status</option>
                    {choices.marital_status && Object.entries(choices.marital_status).map(([key, value]) => (
                    <option key={key} value={key}>
                        {value}
                    </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-3">
                  <label>Education</label>
                  <select
                    className="form-control"
                    name="education"
                    value={formData.education}
                    onChange={handleChange}
                    required
                  >
                    {choices.education && Object.entries(choices.education).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                    {/* Add other options as needed */}
                  </select>
                </div>


                <div className="form-group mb-3">
                <label>State</label>
                <select
                    className="form-control"
                    name="state"
                    value={formData.state}
                    onChange={handleStateChange} // Change handler
                    required
                >
                    <option value="">Select State</option>
                    {states.map((state) => (
                    <option key={state.id} value={state.id}>
                        {state.name} {/* Adjust this based on your state object structure */}
                    </option>
                    ))}
                </select>
                </div>

                {/* City Dropdown */}
                <div className="form-group mb-3">
                <label>City</label>
                <select
                    className="form-control"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                        {city.name} {/* Adjust this based on your city object structure */}
                    </option>
                    ))}
                </select>
                </div>
                
                <div className="form-group mb-3">
                  <label htmlFor="height">Height (cm)</label>
                  <input
                    type="number"
                    className="form-control"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="weight">Weight (kg)</label>
                  <input
                    type="number"
                    className="form-control"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="button" className="btn btn-secondary" onClick={handleBack}>
                  Back
                </button>
                <button type="button" className="btn mx-4 btn-primary" onClick={handleNext}>
                  Next
                </button>
              </>
            )}


            {step === 3 && (
              <>

                <div className="form-group mb-3">
                  <label htmlFor="monthly_salary_range">Monthly Salary Range</label>
                  <input
                    type="text"
                    className="form-control"
                    name="monthly_salary_range"
                    value={formData.monthly_salary_range}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label>Partner Revert</label>
                  <select
                    className="form-control"
                    name="partner_revert"
                    value={formData.partner_revert}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Both">Both</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="partner_requirement">Partner Requirement</label>
                  <textarea
                    className="form-control"
                    name="partner_requirement"
                    value={formData.partner_requirement}
                    onChange={handleChange}
                    rows="3"
                    required
                  />
                </div>


                <div className="form-group mb-3">
                  <label htmlFor="father_name">Father's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="father_name"
                    value={formData.father_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                
                <div className="form-group mb-3">
                  <label htmlFor="father_occupation">Father's Occupation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="father_occupation"
                    value={formData.father_occupation}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="mother_name">Mother's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mother_name"
                    value={formData.mother_name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="mother_occupation">Mother's Occupation</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mother_occupation"
                    value={formData.mother_occupation}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="button" className="btn btn-secondary" onClick={handleBack}>
                  Back
                </button>
                <button type="button" className="btn mx-4 btn-primary" onClick={handleNext}>
                  Next
                </button>
              </>
            )}


            {step === 4 && (
              <>

                <div className="form-group mb-3">
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="about_me">About Me</label>
                  <textarea
                    className="form-control"
                    name="about_me"
                    value={formData.about_me}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="about_family">About Family</label>
                  <textarea
                    className="form-control"
                    name="about_family"
                    value={formData.about_family}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="photo1">Upload Photo 1</label>
                  <input
                    type="file"
                    className="form-control"
                    name="photo1"
                    onChange={handleChange}
                    accept="image/*"
                    
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="photo2">Upload Photo 2</label>
                  <input
                    type="file"
                    className="form-control"
                    name="photo2"
                    onChange={handleChange}
                    accept="image/*"
                  />
                </div>
                <button type="button" className="btn btn-secondary" onClick={handleBack}>
                  Back
                </button>
                
                <button type="submit" className="btn mx-4 btn-success ml-2">
                  Submit
                </button>
              </>
            )}
          </form>
          
        </>)}
    </div>
  );
}

export default Profile;
