import React, { useEffect, useState } from 'react';
import { getUserProfileById } from '../../services/ProfileService';
import { useParams } from 'react-router-dom';

const SingleUserProfile = () => {
  const { id } = useParams(); 
  const [profileData, setProfileData] = useState(null);
  
  useEffect(() => {
    // Fetch user data from API
    const fetchUserData = async (id) => {
      const response = await getUserProfileById(id);
      setProfileData(response.data);
    };
    
    fetchUserData(id);
  }, [id]);

  if (!profileData) return <p>Loading...</p>;

  return (
    <>
      <div className="container mt-5">
        {/* Personal Information Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Personal Information</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Full Name</label>
                <p>{`${profileData.first_name || ''} ${profileData.last_name || ''}`}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Date of Birth</label>
                <p>{profileData.date_of_birth}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Gender</label>
                <p>{profileData.gender}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Phone Number</label>
                <p>{profileData.phone_number}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Height (cm)</label>
                <p>{profileData.height}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Weight (kg)</label>
                <p>{profileData.weight}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">About Me</label>
                <p>{profileData.about_me}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Monthly Salary Range</label>
                <p>{profileData.monthly_salary_range}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">State</label>
                <p>{profileData.state}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">City</label>
                <p>{profileData.city}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Native State Language</label>
                <p>{profileData.native_state_language}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Resident State Language</label>
                <p>{profileData.resident_state_language}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Education</label>
                <p>{profileData.education}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Profession</label>
                <p>{profileData.profession}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Deeni Interest</label>
                <p>{profileData.deeni_interest}</p>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Revert</label>
                <p>{profileData.revert}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Marital Status</label>
                <p>{profileData.marital_status}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Family Information Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Family Information</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Father's Name</label>
                <p>{profileData.father_name}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Mother's Name</label>
                <p>{profileData.mother_name}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Father's Occupation</label>
                <p>{profileData.father_occupation}</p>
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Mother's Occupation</label>
                <p>{profileData.mother_occupation}</p>
              </div>
            </div>


            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">About Family</label>
                <p>{profileData.about_family}</p>
              </div>
            </div>
            
          </div>
        </div>

        {/* Partner Preferences Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Partner Preferences</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Partner Revert</label>
                <p>{profileData.partner_revert}</p>
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">Partner Requirements</label>
                <p>{profileData.partner_requirement}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Images Section */}
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="mb-0">Profile Images</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 mb-3">
                <img src={profileData.photo1} alt="Profile" className="img-fluid" />
              </div>
              <div className="col-md-6 mb-3">
                <img src={profileData.photo2} alt="Profile" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SingleUserProfile;
