import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SingleUserProfile from "./SingleUserProfile";

const UserSearchProfile = ({ profileData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const navigate = useNavigate(); // Initialize the useHistory hook

  const handleShowModal = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProfile(null);
  };

  const handleViewProfile = (profile) => {
    console.log(profile)
    navigate(`/profile/${profile.id}`)
  };


  return (
    <div className="container my-5">
      <h2 className="text-center mb-4">User Profiles</h2>
      <div className="row">
        {profileData.map((profile, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={index}>
            <div className="card text-center shadow-sm border-light p-2">
              <img
                src={profile.photo1 || "default-avatar.png"}
                className="card-img-top rounded-circle mx-auto cursor-pointer"
                alt="Profile"
                style={{ width: "100px", height: "100px" }}
                onClick={() => handleShowModal(profile)} // Open modal on click
              />
              <div className="card-body p-1">
                <h5 className="card-title">{profile.first_name} {profile.last_name}</h5>
                <p className="text-muted mb-1"><strong>State:</strong> {profile.state_name}</p>
                <p className="text-muted mb-1"><strong>Gender:</strong> {profile.gender}</p>
                <p className="text-muted mb-1"><strong>Education:</strong> {profile.education}</p>
                <p className="text-muted mb-1"><strong>Status:</strong> {profile.marital_status}</p>
                <p className="text-muted mb-1"><strong>Interest:</strong> {profile.deeni_interest}</p>
              </div>
              <div className="card-footer p-1">
              <button
                  className="btn btn-primary w-100 btn-sm"
                  onClick={() => handleViewProfile(profile)} // Set selected profile on click
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>



      {/* Modal for Image Carousel */}
      {selectedProfile && (
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{selectedProfile.first_name} {selectedProfile.last_name}'s Photos</h5>
                <button type="button" className="close" onClick={handleCloseModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={selectedProfile.photo1 || "default-avatar.png"} className="d-block w-100" alt="Photo 1" />
                    </div>
                    {selectedProfile.photo2 && (
                      <div className="carousel-item">
                        <img src={selectedProfile.photo2} className="d-block w-100" alt="Photo 2" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSearchProfile;
