import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPaymentStatus } from "../../services/PaymentService";
import UserSearchProfile from "./UserSearchProfile";
import { getAllUserProfileData } from "../../services/ProfileService";

const SearchProfile = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [hasPaid, setHasPaid] = useState(null); // State to track payment status
  const [UserSearchProfileData, SetUserSearchProfileData] = useState([]);
  // gender, state, education, marital status, deeni interest. when filters are applied, matching profiles should be displayed.
 
  const [filters, setFilters] = useState({
    gender: '',
    state: '',
    education: '',
    marital_status: '',
    deeni_interest: '',
  });

  
  const states = useSelector((state) => state.indata.state_data);
  const choices = useSelector((state) => state.indata.all_choice_field_from_backend);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const response = await getPaymentStatus();
        if (response.data.status) {
          setHasPaid(true);
        } else {
          setHasPaid(false);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };

    checkPaymentStatus();
  }, [navigate]);

  useEffect(() => {
    const UserSerchProfilesData = async () => {
      try {
        const response = await getAllUserProfileData();
        SetUserSearchProfileData(response.data.profile_data);
      } catch (error) {
        console.error("Error fetching user profiles:", error);
      }
    };

    UserSerchProfilesData();
  }, [navigate]);

  // Show loading state until payment status is determined
  if (hasPaid === null) {
    return <div>Loading...</div>; // Optionally add a spinner or loading message
  }

  // Filter profiles based on selected criteria
  const filteredProfiles = UserSearchProfileData.filter(profile => {
    return (
      (!filters.gender || profile.gender === filters.gender) &&
      (!filters.state || profile.state === filters.state) &&
      (!filters.education || profile.education === filters.education) &&
      (!filters.marital_status || profile.marital_status === filters.marital_status) &&
      (!filters.deeni_interest || profile.deeni_interest === filters.deeni_interest)
    );
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  return (
    <>
      {hasPaid ? (
        <div className="container my-5">
          <h2 className="text-center mb-4">Search Profiles</h2>
          <div className="row mb-4">
            <div className="col-md-4">
              <select className="form-select" name="gender" onChange={handleFilterChange}>
              <option value="">Select Gender</option>

              {choices.gender && Object.entries(choices.gender).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
              </select>
            </div>
            <div className="col-md-4">
              <select className="form-select" name="state" onChange={handleFilterChange}>
                <option value="">Select State</option>
                {/* Add options for states dynamically */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-4">
              <select className="form-select" name="education" onChange={handleFilterChange}>
                <option value="">Select Education</option>
                {choices.education && Object.entries(choices.education).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
               ))}
              </select>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-4">
              <select className="form-select" name="marital_status" onChange={handleFilterChange}>
                <option value="">Select Marital Status</option>
                  {choices.marital_status && Object.entries(choices.marital_status).map(([key, value]) => (
                    <option key={key} value={key}>
                        {value}
                    </option>
                    ))}
              </select>
            </div>
            <div className="col-md-4">
              <select className="form-select" name="deeni_interest" onChange={handleFilterChange}>
                <option value="">Select Deeni Interest</option>
                {choices.deeni_interest && Object.entries(choices.deeni_interest).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
                ))}
              </select>
            </div>
          </div>
          <UserSearchProfile profileData={filteredProfiles} />
        </div>
      ) : (
        <h1>You need to complete your payment.</h1>
      )}
    </>
  );
};

export default SearchProfile;
