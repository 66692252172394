// ApiService.js
import {axiosProtectedInstance, axiosNormalInstance } from './BaseService';


export const createProfileUrl = async (formData) => {
    try {
      const response = await axiosProtectedInstance.post('/api/profile/create/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure you pass the correct header
        },
      });
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };


export const updateProfileImage = async (formData) => {
      try {
        const response = await axiosProtectedInstance.patch('/api/profile/update/profileimage/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure you pass the correct header
          },
        });
        return response; // Return the response
      } catch (error) {
        throw error; // Rethrow the error for handling in the calling function
      }
    };
  
  

  export const getProfileUrl = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/profile/exists/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };

  
  export const getAllUserProfileData = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/profile/alldata/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  
  export const getProfileChoiceFieldsUrl = async () => {
    try {
      const response = await axiosProtectedInstance.get('/api/profile/choicefields/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };


  export const updateProfile = async (formData) => {
    try {
      const response = await axiosProtectedInstance.patch('/api/profile/updateprofile/', formData);
      return response; // Return the response
    } catch (error) {
      console.log("Error in updateProfile:", error);
      throw error; // Rethrow the error for handling in the calling function
    }
  };


  export const getStatesData = async () => {
    try {
      const response = await axiosNormalInstance.get('/api/profile/states/')
      return response; // Return the response
    } catch (error) {
      throw error; // Rethrow the error for handling in the calling function
    }
  };


export const getAllCityByStateID = async (id) => {
    try {
        const response = await axiosNormalInstance.get(`/api/profile/cities/${id}/`);
        return response;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

  export const getUserProfileById = async (id) => {
    try {
        const response = await axiosProtectedInstance.get(`/api/profile/byid/${id}/`);
        return response;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
  };

  export const getCityById = async (id) => {
    try {
        const response = await axiosNormalInstance.get(`/api/profile/citybyid/${id}/`);
        return response;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};

export const getHomePageDetails = async () => {
    try {
        const response = await axiosNormalInstance.get('/api/port/home_page_details/')
        const { tech_stack, blog_info, footer, port_setting } = response.data;
        const data = { tech_stack, blog_info, footer, port_setting };
        return data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};



export const getAllBlogPost = async () => {
    try {
        const response = await axiosNormalInstance.get('/api/port/get_all_blogposts/')
        return response;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};




export const submitContactUrl = async (contactData) => {
    try {
        console.log("before hitting the url of django", contactData)
        const response = await axiosNormalInstance.post('/api/port/contact/', contactData);
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};
