import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state_data: null,
  user_profile_exist_or_not : null,
  all_choice_field_from_backend :null,
};

const initialData = createSlice({
    name: 'initialDataPopulation',
    initialState, 
    reducers: {
      setStateData: (state, action) => {
        state.state_data = action.payload;
      },

      UserProfileExistOrNot: (state, action) => {
        state.user_profile_exist_or_not = action.payload;
      },

      allChoicesFromBackend: (state, action) => {
        state.all_choice_field_from_backend = action.payload;
      },
    },
  });
  
  export const { setStateData, UserProfileExistOrNot, allChoicesFromBackend } = initialData.actions;
  
  export default initialData.reducer;
