
export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
}

export const setAccessToken = (accessToken) => {
    localStorage.setItem('accessToken', `Bearer ${accessToken}`);
}


export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

export const setRefreshToken = (accessToken) => {
    localStorage.setItem('refreshToken', `${accessToken}`);
}


export const clearTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

}

